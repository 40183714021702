import Decimal from "decimal.js";
import { zip } from "lodash";
import * as React from "react";
import { Table } from "react-bootstrap";
import { ActionButton } from "../../clay/ActionButton";
import { useProjectRecordQuery } from "../../clay/api";
import { Dictionary, Money } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { sumMap } from "../../clay/queryFuncs";
import { QuickCacheApi, useQuickRecords } from "../../clay/quick-cache";
import { newUUID } from "../../clay/uuid";
import { StaticDateTimeWidget } from "../../clay/widgets/DateTimeWidget";
import { FormWrapper, OptionalFormField } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { ListWidget } from "../../clay/widgets/ListWidget";
import { MoneyStatic, MoneyWidget } from "../../clay/widgets/money-widget";
import { PercentageStatic } from "../../clay/widgets/percentage-widget";
import { StaticTextField } from "../../clay/widgets/TextWidget";
import ContingencyItemWidget from "../contingency/ContingencyItemWidget.widget";
import {
    calcContingencyItemTotal,
    ContingencyItem,
} from "../contingency/table";
import { SourceAreaContingency } from "../quotation/source-area";
import { Quotation, QuotationOption, QUOTATION_META } from "../quotation/table";
import { useUser } from "../state";
import { TABLE_STYLE } from "../styles";
import { DETAIL_SHEET_META } from "./detail-sheet/table";
import ProjectScheduleWidget from "./ProjectScheduleWidget.widget";
import { ReactContext as ProjectWidgetReactContext } from "./ProjectWidget.widget";
import { ProjectSchedule } from "./schedule";
import { Project, PROJECT_META, resolveSchedules } from "./table";

export type Data = Project;

export const Fields = {
    contractDetailsDate: StaticDateTimeWidget,

    projectSchedules: ListWidget(ProjectScheduleWidget, {
        merge: (state, data, incomingState, incomingData) => {
            return {
                state,
                data: {
                    ...data,
                    price: data.price.plus(incomingData.price),
                },
            };
        },
        emptyOk: true,
    }),
    projectContingencyItems: ListWidget(ContingencyItemWidget, {
        emptyOk: true,
    }),
    anticipatedProjectValue: OptionalFormField(MoneyWidget),
};

export function sourceToProjectContingency(
    contingencyItem: SourceAreaContingency,
    originalOption: QuotationOption
): ContingencyItem {
    return {
        nonCfExpense: false,
        id: newUUID(),
        originalOptionId: originalOption.id.uuid,
        originalId: contingencyItem.id.uuid,
        schedule: null,
        masterFormatCode: contingencyItem.masterFormatCode,
        itemType: contingencyItem.itemType,
        type: contingencyItem.type,
        description: contingencyItem.description,
        quantity: contingencyItem.quantity,
        rate: contingencyItem.priceRate,
        certifiedForemanRate: contingencyItem.costRate,
        projectDescription: originalOption.projectDescription,
        hours: contingencyItem.hours,
        hourRate: contingencyItem.hourRate,
        materials: contingencyItem.materials,
        materialsRate: contingencyItem.materialsRate,
        groupCode: null,
        billingItem: null,
    };
}

function actionImportScheduleFromQuotation(
    state: State,
    data: Project,
    quotations: Quotation[]
) {
    const selectedOptions = quotations
        .flatMap((quotation) => quotation.options)
        .filter(
            (option) => data.selectedOptions.indexOf(option.id.uuid) !== -1
        );

    const projectSchedules: ProjectSchedule[] = selectedOptions.flatMap(
        (option) => {
            const schedules: ProjectSchedule[] =
                option.schedules.length > 0
                    ? zip(option.schedules, option.details.schedules).map(
                          ([schedule, detail]) =>
                              ({
                                  id: newUUID(),
                                  originalOptionId: option.id.uuid,
                                  originalScheduleId: schedule!.id.uuid,
                                  name: schedule!.name,
                                  description: schedule!.name,
                                  price: detail!.total,
                                  certifiedForemanContractAmount: new Decimal(
                                      0
                                  ),
                                  projectDescription:
                                      schedule!.projectDescription,
                                  contingencyAllowance: false,
                                  groupCode: null,
                                  billingItem: null,
                              } satisfies ProjectSchedule)
                      )
                    : [
                          {
                              id: newUUID(),
                              originalOptionId: option.id.uuid,
                              originalScheduleId: null,
                              name: option.name,
                              description: option.description,
                              price: option.details.total.minus(
                                  option.details.contingencyPriceTotal
                              ),
                              certifiedForemanContractAmount: new Decimal(0),
                              projectDescription: option.projectDescription,
                              contingencyAllowance: false,
                              groupCode: null,
                              billingItem: null,
                          } satisfies ProjectSchedule,
                      ].filter((x) => !x.price.isZero());

            return schedules;
        }
    );

    const inner = ListWidget(ProjectScheduleWidget).initialize(
        projectSchedules,
        {}
    );

    const contingencyItems = selectedOptions.flatMap((option) =>
        option.details.contingencies.map((contingencyItem) =>
            sourceToProjectContingency(contingencyItem, option)
        )
    );

    const inner2 = ListWidget(ContingencyItemWidget).initialize(
        contingencyItems,
        {}
    );

    return {
        state: {
            ...state,
            projectSchedules: inner.state,
            projectContingencyItems: inner2.state,
        },
        data: {
            ...data,
            contractDetailsDate: new Date(),
            projectSchedules: inner.data,
            projectContingencyItems: inner2.data,
            hasContingencyItems: inner2.data.length > 0,
        },
    };
}

function actionClearSchedule(
    state: State,
    data: Project,
    quotations: Quotation[]
) {
    const projectSchedules: ProjectSchedule[] = [];

    const inner = ListWidget(ProjectScheduleWidget).initialize(
        projectSchedules,
        {}
    );

    return {
        state: {
            ...state,
            projectSchedules: inner.state,
        },
        data: {
            ...data,
            projectSchedules: inner.data,
        },
    };
}

function reduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    const inner = baseReduce(state, data, action, context);
    return {
        state: inner.state,
        data: resolveSchedules(inner.data),
    };
}

function Component(props: Props) {
    const user = useUser();

    const projectContext = React.useContext(ProjectWidgetReactContext)!;

    const quotations = useQuickRecords(
        QUOTATION_META,
        props.data.selectedQuotations as string[]
    );
    const detailSheets = useProjectRecordQuery(
        DETAIL_SHEET_META,
        props.data.id.uuid
    );

    React.useEffect(() => {
        if (props.data.contractDetailsDate === null && quotations) {
            props.dispatch({
                type: "IMPORT_SCHEDULE_FROM_QUOTATION",
                quotations,
            });
        }
    }, [props.data, quotations]);

    const changeOrderTotal = detailSheets
        ? sumMap(
              detailSheets.filter((x) => x.change).flatMap((x) => x.schedules),
              (x) => x.price
          )
        : new Decimal(0);

    const hasDetailSheets = detailSheets && detailSheets.length > 0;
    const totalContractValue = sumMap(
        hasDetailSheets
            ? detailSheets.filter((x) => !x.change).flatMap((x) => x.schedules)
            : props.data.projectSchedules,
        (x) => x.price
    );
    const totalContingencyItems = sumMap(
        hasDetailSheets
            ? detailSheets.flatMap((x) => x.contingencyItems)
            : props.data.projectContingencyItems,
        calcContingencyItemTotal
    );

    const totalTotal = totalContingencyItems
        .plus(totalContractValue)
        .plus(changeOrderTotal);

    function totalRow(name: String, amount: Money) {
        return (
            <tr>
                <th>{name}</th>
                <td>
                    <MoneyStatic value={amount} />
                </td>
                <td>
                    <PercentageStatic
                        value={amount.dividedBy(totalTotal).toDecimalPlaces(2)}
                    />
                </td>
            </tr>
        );
    }

    return (
        <>
            <FormWrapper label="Contract Details Date">
                <div style={{ display: "flex" }}>
                    <widgets.contractDetailsDate />

                    <div style={{ width: "1em" }} />
                    <ActionButton
                        status={props.status}
                        onClick={() =>
                            projectContext.dispatch({
                                type: "CANCEL_CONTRACT_DETAILS",
                            })
                        }
                    >
                        Cancel
                    </ActionButton>
                </div>
            </FormWrapper>
            <Table style={{ width: "fit-content" }}>
                <thead>
                    <th />
                    <th>Contract Value</th>
                    <th>Percentage</th>
                </thead>
                <tbody>
                    {totalRow("Fixed Price Items", totalContractValue)}
                    {totalRow(
                        "Unit Rate Items & Allowances",
                        totalContingencyItems
                    )}
                    {totalRow("Change Orders", changeOrderTotal)}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total</th>
                        <th>
                            <MoneyStatic value={totalTotal} />
                        </th>
                    </tr>
                </tfoot>
            </Table>

            <h2>Schedule of Values - Fixed Price Items</h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginTop: "2em",
                    marginBottom: "2em",
                }}
            >
                <table
                    {...TABLE_STYLE}
                    style={{
                        width: "100%",
                        maxWidth: "75em",
                        marginRight: "auto",
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ width: "2em" }} />
                            <th>Description</th>
                            <th style={{ width: "10em" }}>Price</th>
                            <th style={{ width: "1em" }} />
                        </tr>
                    </thead>
                    {hasDetailSheets ? (
                        <tbody>
                            {detailSheets
                                .flatMap((sheet) => sheet.schedules)
                                .map((schedule) => (
                                    <tr key={schedule.id.uuid}>
                                        <td />
                                        <td>
                                            <StaticTextField
                                                value={schedule.description}
                                            />
                                        </td>
                                        <td>
                                            <MoneyStatic
                                                value={schedule.price}
                                            />
                                        </td>
                                        <td />
                                    </tr>
                                ))}
                        </tbody>
                    ) : (
                        <widgets.projectSchedules
                            containerClass="tbody"
                            extraItemForAdd
                            itemProps={{
                                dividedDescription:
                                    props.data
                                        .projectSchedulesDividedDescription,
                            }}
                        />
                    )}
                    <tfoot>
                        <tr>
                            <th />
                            <th>Total Contract Value</th>
                            <th>
                                <MoneyStatic value={totalContractValue} />
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <h2>Schedule of Values - Unit Rate Items & Allowances</h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginTop: "2em",
                    marginBottom: "2em",
                }}
            >
                <table
                    {...TABLE_STYLE}
                    style={{
                        width: "100%",
                        maxWidth: "75em",
                        marginRight: "auto",
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ width: "2em" }} />
                            <th>Description</th>
                            <th style={{ width: "10em" }}>
                                Contract Allowance
                            </th>
                            <th style={{ width: "1em" }} />
                        </tr>
                    </thead>
                    {hasDetailSheets ? (
                        <tbody>
                            {detailSheets
                                .flatMap((x) => x.contingencyItems)
                                .map((contingencyItem) => (
                                    <tr>
                                        <th />
                                        <td>
                                            <StaticTextField
                                                value={
                                                    contingencyItem.description
                                                }
                                            />
                                        </td>
                                        <td>
                                            <MoneyStatic
                                                value={calcContingencyItemTotal(
                                                    contingencyItem
                                                )}
                                            />
                                        </td>
                                        <td />
                                    </tr>
                                ))}
                        </tbody>
                    ) : (
                        <widgets.projectContingencyItems
                            containerClass="tbody"
                            extraItemForAdd
                        />
                    )}
                    <tfoot>
                        <tr>
                            <th />
                            <th>Total Contingency Value</th>
                            <th>
                                <MoneyStatic value={totalContingencyItems} />
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <h2>Schedule of Values - Change Orders</h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginTop: "2em",
                    marginBottom: "2em",
                }}
            >
                <table
                    {...TABLE_STYLE}
                    style={{
                        width: "100%",
                        maxWidth: "75em",
                        marginRight: "auto",
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ width: "2em" }} />
                            <th>Change Orders</th>
                            <th style={{ width: "10em" }}>Price</th>
                            <th style={{ width: "1em" }} />
                        </tr>
                    </thead>
                    <tbody>
                        {detailSheets &&
                            detailSheets
                                .filter((x) => x.change)
                                .flatMap((x) => x.schedules)
                                .map((option) => (
                                    <tr>
                                        <td></td>
                                        <td>
                                            <StaticTextField
                                                value={option.description}
                                            />
                                        </td>
                                        <td>
                                            <MoneyStatic value={option.price} />
                                        </td>
                                    </tr>
                                ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th />
                            <th>Total Contract Value</th>
                            <th>
                                <MoneyStatic
                                    value={
                                        detailSheets
                                            ? sumMap(
                                                  detailSheets
                                                      .filter((x) => x.change)
                                                      .flatMap(
                                                          (x) => x.schedules
                                                      ),
                                                  (x) => x.price
                                              )
                                            : new Decimal(0)
                                    }
                                />
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.contractDetailsDate> &
    WidgetContext<typeof Fields.projectSchedules> &
    WidgetContext<typeof Fields.projectContingencyItems> &
    WidgetContext<typeof Fields.anticipatedProjectValue>;
type ExtraProps = {};
type BaseState = {
    contractDetailsDate: WidgetState<typeof Fields.contractDetailsDate>;
    projectSchedules: WidgetState<typeof Fields.projectSchedules>;
    projectContingencyItems: WidgetState<typeof Fields.projectContingencyItems>;
    anticipatedProjectValue: WidgetState<typeof Fields.anticipatedProjectValue>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "CONTRACT_DETAILS_DATE";
          action: WidgetAction<typeof Fields.contractDetailsDate>;
      }
    | {
          type: "PROJECT_SCHEDULES";
          action: WidgetAction<typeof Fields.projectSchedules>;
      }
    | {
          type: "PROJECT_CONTINGENCY_ITEMS";
          action: WidgetAction<typeof Fields.projectContingencyItems>;
      }
    | {
          type: "ANTICIPATED_PROJECT_VALUE";
          action: WidgetAction<typeof Fields.anticipatedProjectValue>;
      }
    | { type: "IMPORT_SCHEDULE_FROM_QUOTATION"; quotations: Quotation[] }
    | { type: "CLEAR_SCHEDULE"; quotations: Quotation[] };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.contractDetailsDate,
        data.contractDetailsDate,
        cache,
        "contractDetailsDate",
        errors
    );
    subvalidate(
        Fields.projectSchedules,
        data.projectSchedules,
        cache,
        "projectSchedules",
        errors
    );
    subvalidate(
        Fields.projectContingencyItems,
        data.projectContingencyItems,
        cache,
        "projectContingencyItems",
        errors
    );
    subvalidate(
        Fields.anticipatedProjectValue,
        data.anticipatedProjectValue,
        cache,
        "anticipatedProjectValue",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "CONTRACT_DETAILS_DATE": {
            const inner = Fields.contractDetailsDate.reduce(
                state.contractDetailsDate,
                data.contractDetailsDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, contractDetailsDate: inner.state },
                data: { ...data, contractDetailsDate: inner.data },
            };
        }
        case "PROJECT_SCHEDULES": {
            const inner = Fields.projectSchedules.reduce(
                state.projectSchedules,
                data.projectSchedules,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectSchedules: inner.state },
                data: { ...data, projectSchedules: inner.data },
            };
        }
        case "PROJECT_CONTINGENCY_ITEMS": {
            const inner = Fields.projectContingencyItems.reduce(
                state.projectContingencyItems,
                data.projectContingencyItems,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectContingencyItems: inner.state },
                data: { ...data, projectContingencyItems: inner.data },
            };
        }
        case "ANTICIPATED_PROJECT_VALUE": {
            const inner = Fields.anticipatedProjectValue.reduce(
                state.anticipatedProjectValue,
                data.anticipatedProjectValue,
                action.action,
                subcontext
            );
            return {
                state: { ...state, anticipatedProjectValue: inner.state },
                data: { ...data, anticipatedProjectValue: inner.data },
            };
        }
        case "IMPORT_SCHEDULE_FROM_QUOTATION":
            return actionImportScheduleFromQuotation(
                state,
                data,
                action.quotations
            );
        case "CLEAR_SCHEDULE":
            return actionClearSchedule(state, data, action.quotations);
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    contractDetailsDate: function (
        props: WidgetExtraProps<typeof Fields.contractDetailsDate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CONTRACT_DETAILS_DATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "contractDetailsDate",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.contractDetailsDate.component
                state={context.state.contractDetailsDate}
                data={context.data.contractDetailsDate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Contract Details Date"}
            />
        );
    },
    projectSchedules: function (
        props: WidgetExtraProps<typeof Fields.projectSchedules> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_SCHEDULES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "projectSchedules", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectSchedules.component
                state={context.state.projectSchedules}
                data={context.data.projectSchedules}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Schedules"}
            />
        );
    },
    projectContingencyItems: function (
        props: WidgetExtraProps<typeof Fields.projectContingencyItems> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_CONTINGENCY_ITEMS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectContingencyItems",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectContingencyItems.component
                state={context.state.projectContingencyItems}
                data={context.data.projectContingencyItems}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Contingency Items"}
            />
        );
    },
    anticipatedProjectValue: function (
        props: WidgetExtraProps<typeof Fields.anticipatedProjectValue> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ANTICIPATED_PROJECT_VALUE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "anticipatedProjectValue",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.anticipatedProjectValue.component
                state={context.state.anticipatedProjectValue}
                data={context.data.anticipatedProjectValue}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Anticipated Project Value"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let contractDetailsDateState;
        {
            const inner = Fields.contractDetailsDate.initialize(
                data.contractDetailsDate,
                subcontext,
                subparameters.contractDetailsDate
            );
            contractDetailsDateState = inner.state;
            data = { ...data, contractDetailsDate: inner.data };
        }
        let projectSchedulesState;
        {
            const inner = Fields.projectSchedules.initialize(
                data.projectSchedules,
                subcontext,
                subparameters.projectSchedules
            );
            projectSchedulesState = inner.state;
            data = { ...data, projectSchedules: inner.data };
        }
        let projectContingencyItemsState;
        {
            const inner = Fields.projectContingencyItems.initialize(
                data.projectContingencyItems,
                subcontext,
                subparameters.projectContingencyItems
            );
            projectContingencyItemsState = inner.state;
            data = { ...data, projectContingencyItems: inner.data };
        }
        let anticipatedProjectValueState;
        {
            const inner = Fields.anticipatedProjectValue.initialize(
                data.anticipatedProjectValue,
                subcontext,
                subparameters.anticipatedProjectValue
            );
            anticipatedProjectValueState = inner.state;
            data = { ...data, anticipatedProjectValue: inner.data };
        }
        let state = {
            initialParameters: parameters,
            contractDetailsDate: contractDetailsDateState,
            projectSchedules: projectSchedulesState,
            projectContingencyItems: projectContingencyItemsState,
            anticipatedProjectValue: anticipatedProjectValueState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: reduce,
};
export default Widget;
type Widgets = {
    contractDetailsDate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.contractDetailsDate>
    >;
    projectSchedules: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectSchedules>
    >;
    projectContingencyItems: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectContingencyItems>
    >;
    anticipatedProjectValue: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.anticipatedProjectValue>
    >;
};
// END MAGIC -- DO NOT EDIT
