import * as React from "react";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { DecimalDefaultWidget } from "../../clay/widgets/DecimalDefaultWidget";
import { Optional } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { PercentageWidget } from "../../clay/widgets/percentage-widget";
import { MarkupPercentages, MARKUP_PERCENTAGES_META } from "./table";

export type Data = MarkupPercentages;

export const Fields = {
    labour: Optional(PercentageWidget),
    materials: DecimalDefaultWidget,
};

export function Component(props: Props) {
    return (
        <>
            <td>
                <widgets.labour />
            </td>
            <td className="materials-column">
                <widgets.materials defaultData={props.data.labour} percentage />{" "}
            </td>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.labour> &
    WidgetContext<typeof Fields.materials>;
type ExtraProps = {};
type BaseState = {
    labour: WidgetState<typeof Fields.labour>;
    materials: WidgetState<typeof Fields.materials>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "LABOUR"; action: WidgetAction<typeof Fields.labour> }
    | { type: "MATERIALS"; action: WidgetAction<typeof Fields.materials> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.labour, data.labour, cache, "labour", errors);
    subvalidate(Fields.materials, data.materials, cache, "materials", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "LABOUR": {
            const inner = Fields.labour.reduce(
                state.labour,
                data.labour,
                action.action,
                subcontext
            );
            return {
                state: { ...state, labour: inner.state },
                data: { ...data, labour: inner.data },
            };
        }
        case "MATERIALS": {
            const inner = Fields.materials.reduce(
                state.materials,
                data.materials,
                action.action,
                subcontext
            );
            return {
                state: { ...state, materials: inner.state },
                data: { ...data, materials: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    labour: function (
        props: WidgetExtraProps<typeof Fields.labour> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LABOUR",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "labour", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.labour.component
                state={context.state.labour}
                data={context.data.labour}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Labour"}
            />
        );
    },
    materials: function (
        props: WidgetExtraProps<typeof Fields.materials> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MATERIALS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "materials", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.materials.component
                state={context.state.materials}
                data={context.data.materials}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Materials"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: MARKUP_PERCENTAGES_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let labourState;
        {
            const inner = Fields.labour.initialize(
                data.labour,
                subcontext,
                subparameters.labour
            );
            labourState = inner.state;
            data = { ...data, labour: inner.data };
        }
        let materialsState;
        {
            const inner = Fields.materials.initialize(
                data.materials,
                subcontext,
                subparameters.materials
            );
            materialsState = inner.state;
            data = { ...data, materials: inner.data };
        }
        let state = {
            initialParameters: parameters,
            labour: labourState,
            materials: materialsState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext
                    meta={MARKUP_PERCENTAGES_META}
                    value={props.data}
                >
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    labour: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.labour>
    >;
    materials: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.materials>
    >;
};
// END MAGIC -- DO NOT EDIT
